export default {
  apiUrl: "http://yoursite.com/api/",
};

const siteConfig = {
  siteName: "Mintage",
  siteIcon: "ion-flash",
  footerText: "Mintage ©2017",
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "english";
export { siteConfig, language, themeConfig };
