import permissions from "./permissionConst";
import { store } from "./redux/store";

const isAllowed = (permission) => {
  const state = store.getState();
  let roles = [];
  // let permissionList = [];
  if (state && state.Auth) roles = state.Auth.get("roles");
  // if (user && user.role_data) permissionList = user.role_data.role_permission;
  if (roles.length > 0)
    for (let index = 0; index < roles.length; index++) {
      const r = roles[index];
      if (r.route_name == permission) {
        return true;
      }
    }
  return false;
};

export const isAllowedDashboardCheck = (permission1,permission2) => {
  const state = store.getState();
  let roles = [];
  // let permissionList = [];
  if (state && state.Auth) roles = state.Auth.get("roles");
  // if (user && user.role_data) permissionList = user.role_data.role_permission;
  if (roles.length > 0)
    for (let index = 0; index < roles.length; index++) {
      const r = roles[index];
      if (r.route_name !== permission1 && r.route_name == permission2) {
        return true;
      }
    }
  return false;
};

export default isAllowed;

// import permissions from "./permissionConst";
// import { store } from "./redux/store";

// const isAllowed = (permission) => {
//   const state = store.getState();
//   let user = null;
//   let permissionList = [];
//   if (state && state.Auth) user = state.Auth.get("user");
//   if (user && user.role_data) permissionList = user.role_data.role_permission;
//   if (permissionList.length > 0)
//     for (let index = 0; index < permissionList.length; index++) {
//       const r = permissionList[index];
//       if (r.route_name == permission) {
//         return true;
//       }
//     }
//   return false;
// };

// export default isAllowed;
