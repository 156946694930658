import { all, takeEvery, put, fork, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken } from "../../helpers/utility";
import actions from "./actions";
// import options from "../../containers/Sidebar/options";
import isAllowed from "../../accessControl";
import permissions from "../../permissionConst";

const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* (payload) {
    //console.log("step 3", payload);
    if (fakeApiCall) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: payload.token.token,
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}
export function* firstTimeLogin() {
  yield takeEvery(actions.FIRST_TIME_LOGIN, function* (payload) {
    const options = [];
    //console.log(payload, "first time login");
    yield put(actions.login(payload.data.token));
    //console.log("roles data", payload.data.permissions);
    yield put(actions.checkRoles(payload.data.permissions));
    yield put(
      actions.storeUser({
        ...payload.data.user,
        is_admin: payload.data.user.is_active,
      })
    );
    yield put({
      type: actions.LOGIN_DONE,
    });
    if (isAllowed(permissions.dashboard)) {
      options.push({
        key: "dashboard",
        label: "Dashboard",
        leftIcon: "fas fa-tachometer-alt",
        is_admin: true,
      });
    }
    // if (isAllowed(permissions.listChannelsCategory)) 
    // {
    //   options.push({
    //     key: "category",
    //     label: "Categories",
    //     leftIcon: "fa fa-bars",
    //     is_admin: true,
    //   });
    // }

    if (isAllowed(permissions.listSubAdmins)) {
      options.push({
        key: "portal-users",
        label: "Sub-admin Management",
        leftIcon: "fas fa-users",
        is_admin: true,
      });
    }

    if (isAllowed(permissions.rolesListing)) {
      options.push({
        key: "roles",
        label: "Roles",
        leftIcon: "fa fa-user-tag",
        is_admin: true,
      });
    }
    if (isAllowed(permissions.listUser)) {
      options.push({
        key: "end-users",
        label: "Users",
        leftIcon: "fas fa-users",
        is_admin: true,
      });
    }
  
    if (isAllowed(permissions.nftList))
    {
      options.push({
        key: "nft",
        label: "NFT",
        leftIcon: "fa fa-usd",
        is_admin: true,
      });
    }

    if (isAllowed(permissions.nftReport))
    {
      options.push({
        key: "reports",
        label: "NFT Report",
        leftIcon: "fa fa-envelope",
        is_admin: true,
      });
    }

    if (isAllowed(permissions.nftReportList))
    {
      options.push({
        key: "ledger",
        label: "Ledger",
        leftIcon: "fa fa-file",
        is_admin: true,
      });
    }
  
    if (isAllowed(permissions.subscriberList))
    {
      options.push({
        key: "subscriber",
        label: "Subscribers",
        leftIcon: "fas fa-users",
        is_admin: true,
      });
    }
    if (isAllowed(permissions.emailTemplatesList)) 
    {
      options.push({
        key: "email-template",
        label: "Email Template",
        leftIcon: "fa fa-envelope",
        is_admin: true,
      });
    }
    // if (isAllowed(permissions.listWithDrawRequest)) 
    // {
    //   options.push({
    //     key: "withdraw-requests",
    //     label: "Withdraw Requests",
    //     leftIcon: "fas fa-money-check-alt",
    //     is_admin: true,
    //   });
    // }
    // if (isAllowed(permissions.enquiriesListing)) 
    // {
    //   options.push({
    //     key: "enquiries",
    //     label: "Contact Us",
    //     leftIcon: "fas fa-comment-alt",
    //     is_admin: true,
    //   });
    // }
    if (isAllowed(permissions.staticContentListing)) 
    {
      options.push({
        key: "static-pages",
        label: "Static pages",
        leftIcon: "fas fa-sticky-note",
        is_admin: true,
      });
    }

    // {
    //   options.push({
    //     key: "users",
    //     label: "users",
    //     leftIcon: "fas fa-sticky-note",
    //     is_admin: true,
    //   });
    // }

    // if (isAllowed(permissions.emailTemplatesList))
    // options.push({
    //   key: "email-templates",
    //   label: "Email Templates",
    //   leftIcon: "fa fa-envelope",
    //   is_admin: true,
    // });
    // if (isAllowed(permissions.broadcastNotificationList)) 
    // {
    //   options.push({
    //     key: "broadcast-notifications",
    //     label: "Broadcast Notifications",
    //     leftIcon: "fas fa-bullhorn",
    //     is_admin: true,
    //   });
    // }
    // if (isAllowed(permissions.transactionHistory)) 
    // {
    //   options.push({
    //     key: "transaction-history",
    //     label: "Transaction history",
    //     leftIcon: "fas fa-history",
    //     is_admin: true,
    //   });
    // }
    // if (isAllowed(permissions.searchLog)) 
    // {
    //   options.push({
    //     key: "search-log",
    //     label: "Search log",
    //     leftIcon: "fas fa-search",
    //     is_admin: true,
    //   });
    // }
    // if (isAllowed(permissions.refundRequestList)) 
    // {
    //   options.push({
    //     key: "refund-request",
    //     label: "Refund Request",
    //     leftIcon: "fas fa-money-check-alt",
    //     is_admin: true,
    //   });
    // }


    // if (isAllowed(permissions.getAdminSetting)) 
    // {
    //   options.push({
    //     key: "admin-settings",
    //     label: "Settings",
    //     leftIcon: "fas fa-cog",
    //     is_admin: true,
    //   });
    // }


    yield put({
      type: actions.SET_SIDEBAR,
      options: options,
    });
  });
}

export function* updateProfile() {
  yield takeEvery(actions.UPDATE_PROFILE, function* (payload) {
    //console.log("payload", payload);
    const data = yield select();
    const newUser = {
      ...data.Auth.get("user"),
      ...payload.user.user,
    };
    //console.log("update profile saga", payload, data.Auth.get("user"));
    yield put({ type: actions.UPDATE_PROFILE_FINISHED, payload: newUser });
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    // yield put(push("/"));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: "Profile",
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(firstTimeLogin),
    fork(updateProfile),
  ]);
}
