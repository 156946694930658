import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
import isAllowed, {isAllowedDashboardCheck} from "../../accessControl";
import permissions from "../../permissionConst";

class AppRouter extends Component {
  render() {
    const routes = [
      isAllowed(permissions.dashboard) &&
      {
        path: "dashboard",
        component: asyncComponent(() => import("../dashboard")),
      },
      // {
      //   path: "blankPage",
      //   component: asyncComponent(() => import("../blankPage")),
      // },
      // isAllowed(permissions.adminChangePassword) &&
      {
        path: "change-password",
        component: asyncComponent(() =>
          import("../Page/change_password/changePassword")
        ),
      },
      isAllowed(permissions.listUser) &&
      {
        path: "end-users",
        component: asyncComponent(() => import("../Page/users/users")),
      },

      isAllowed(permissions.nftList) &&
      {
        path: "nft",
        component: asyncComponent(() => import("../Page/nft/users")),
      },

      isAllowed(permissions.nftReport) &&
      {
        path: "reports",
        component: asyncComponent(() => import("../Page/nft_report/users")),
      },

      isAllowed(permissions.nftReportList) &&
      {
        path: "ledger",
        component: asyncComponent(() => import("../Page/reports/users")),
      },

      isAllowed(permissions.updateAdminProfile) &&
      {
        path: "edit-profile",
        component: asyncComponent(() =>
          import("../Page/edit_profile/editProfile")
        ),
      },

      isAllowed(permissions.subscriberList) &&
      {
        path: "subscriber",
        component: asyncComponent(() =>
          import("../Page/subscribers/index")
        ),
      },
      // isAllowed(permissions.enquiriesListing) && 
      // {
      //   path: "enquiries",
      //   component: asyncComponent(() => import("../Page/enquiries/enquiries")),
      // },
      isAllowed(permissions.staticContentListing) && 
      {
        path: "static-pages",
        component: asyncComponent(() =>
          import("../Page/static_page/staticPages")
        ),
      },

      isAllowed(permissions.emailTemplatesList) &&
      {
        path: "email-template",
        component: asyncComponent(() =>
          import("../Page/email_template/emailTemplate")
        ),
      },

      // {
      //   path: "users",
      //   component: asyncComponent(() =>
      //     import("../Page/users/users")
      //   ),
      // },
      // isAllowed(permissions.listChannelsCategory) && 
      // {
      //   path: "category",
      //   component: asyncComponent(() => import("../Page/category/category")),
      // },
      // isAllowed(permissions.celebrityListing) && 
      // {
      //   path: "youtubers",
      //   component: asyncComponent(() => import("../Page/youtubers/youtubers")),
      // },
      // isAllowed(permissions.listWithDrawRequest) && 
      // {
      //   path: "withdraw-requests",
      //   component: asyncComponent(() =>
      //     import("../Page/withdraw_request/withDrawRequest")
      //   ),
      // },
      isAllowed(permissions.rolesListing) &&
      {
        path: "roles",
        component: asyncComponent(() => import("../Page/roles")),
      },
      isAllowed(permissions.createRole) &&
      {
        path: "roles/create",
        component: asyncComponent(() => import("../Page/roles/addRole")),
      },
      isAllowed(permissions.updateRole) &&
      {
        path: "roles/edit/:id/:name",
        component: asyncComponent(() => import("../Page/roles/editRole")),
      },
      isAllowed(permissions.listSubAdmins) &&
      {
        path: "portal-users",
        component: asyncComponent(() => import("../Page/portalUsers")),
      },
      isAllowed(permissions.createSubAdmin) &&
      {
        path: "portal-users/create",
        component: asyncComponent(() => import("../Page/portalUsers/addUser")),
      },
      isAllowed(permissions.editSubAdmin) &&
      {
        path: "portal-users/edit/:id",
        component: asyncComponent(() => import("../Page/portalUsers/editUser")),
      },
      isAllowed(permissions.dashboard) ?
      {
        path: "",
        component: asyncComponent(() => import("../dashboard")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.listSubAdmins) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/portalUsers")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.rolesListing) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/roles")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.listUser) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/users/users")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.nftList) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/nft/users")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.nftReport) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/nft_report/users")),
      } :
      isAllowedDashboardCheck(permissions.dashboard, permissions.nftReportList) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/reports/users")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.subscriberList) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/subscribers/index")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.emailTemplatesList) ?
      {
        path: "",
        component: asyncComponent(() => import("../Page/email_template/emailTemplate")),
      }:
      isAllowedDashboardCheck(permissions.dashboard, permissions.staticContentListing) &&
      {
        path: "",
        component: asyncComponent(() => import("../Page/static_page/staticPages")),
      },
      // isAllowed(permissions.emailTemplatesList) && 
      // {
      //   path: "email-templates",
      //   component: asyncComponent(() =>
      //     import("../Page/email_template/emailTemplate")
      //   ),
      //   module: "email_template",
      // },
      // isAllowed(permissions.broadcastNotificationList) && 
      // {
      //   path: "broadcast-notifications",
      //   component: asyncComponent(() =>
      //     import("../Page/broadcast_notifications/broadcast")
      //   ),
      // },
      // isAllowed(permissions.transactionHistory) && 
      // {
      //   path: "transaction-history",
      //   component: asyncComponent(() =>
      //     import("../Page/transaction_history/transactionHistory")
      //   ),
      // },
      // isAllowed(permissions.getAdminSetting) && 
      // {
      //   path: "admin-settings",
      //   component: asyncComponent(() =>
      //     import("../Page/admin_setting/adminSetting")
      //   ),
      // },
      // isAllowed(permissions.searchLog) && 
      // {
      //   path: "search-log",
      //   component: asyncComponent(() => import("../Page/searchLog/searchLog")),
      // },
      // isAllowed(permissions.refundRequestList) && 
      // {
      //   path: "refund-request",
      //   component: asyncComponent(() =>
      //     import("../Page/refund_request/withDrawRequest")
      //   ),
      // },
    ];
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
        {/* <Redirect to={`/404`} /> */}
      </div>
    );
  }
}

export default AppRouter;
