const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  CHECK_ROLES: "CHECK_ROLES",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_FINISHED: "UPDATE_PROFILE_FINISHED",
  STORE_USER: "STORE_USER",
  FIRST_TIME_LOGIN: "FIRST_TIME_LOGIN",
  LOGIN_DONE: "LOGIN_DONE",
  SET_SIDEBAR: "SET_SIDEBAR",

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  firstTimeLogin: (data) => {
    return {
      type: actions.FIRST_TIME_LOGIN,
      data,
    };
  },

  checkRoles: (data) => ({
    type: actions.CHECK_ROLES,
    roleData: data,
  }),

  login: (token) => {
    return {
      type: actions.LOGIN_SUCCESS,
      token,
    };
  },
  updateProfile: (user) => {
    return {
      type: actions.UPDATE_PROFILE,
      user,
    };
  },
  storeUser: (user) => {
    //console.log("step 2", user);
    return {
      type: actions.STORE_USER,
      user,
    };
  },

  logout: () => ({
    type: actions.LOGOUT,
  }),
};

export default actions;
