import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";
import logo from "../../image/click-mall-icon.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link
              to={{
                hash: "#",
              }}
            >
              <img
                src={`${process.env.REACT_APP_IMG_PATH}images/Frame+3.png`}
                alt="Icon"
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <img
            src={`${process.env.REACT_APP_IMG_PATH}images/Frame+2.png`}
          />
        </h3>
      )}
    </div>
  );
};
