import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
  idToken: null,
  roles: ["admin"],
  user: null,
  isPermissionsSet: false,
  isUserDataSet: false,
  options: [],
});

export default function authReducer(state = initState, action) {
  // console.log('>>>action', action)
  switch (action.type) {
    case actions.LOGIN_SUCCESS: {
      //console.log(">>>action LOGIN SUCCESS", action);

      return state.set("idToken", action.token);
    }
    case actions.STORE_USER: {
      //console.log(">>>action Store user", action);

      return state.set("user", action.user);
    }
    // case actions.UPDATE_PROFILE: {
    //   const newUser = {
    //     ...state.get("user"),
    //     ...action.user.user,
    //   };

    //   console.log("UPDATE REQUEST REQUEST", action);
    //   return state.set("user", newUser);
    // }
    case actions.UPDATE_PROFILE_FINISHED: {
      return state.set("user", action.payload);
    }
    case actions.CHECK_ROLES: {
      //console.log(">>>action check roles", action);

      return state.set("roles", action.roleData);
    }
    case actions.LOGIN_DONE: {
      return state.set("isPermissionsSet", true).set("isUserDataSet", true);
    }
    case actions.SET_SIDEBAR: {
      return state.set("options", action.options);
    }
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
