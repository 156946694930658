const permissions = {
  dashboard: "/dashboard/index",
  languages: "languages",
  addNewLanguages: "add-language",
  addUser: "/users/create",
  listUser: "/users/index",
  editUser: "/users/edit",
  languageList: "get-language-list",
  deleteUser: "/users/destroy",
  addChannelCategory: "add-channel-categtory",
  listChannelsCategory: "get-channel-list",
  editChannelCategory: "edit-channel-details",
  deleteChannelCategory: "delete-channel",
  updateUserStatus: "/users/activation",
  updateAdminProfile: "/users/profile_update",
  adminChangePassword: "change-admin-password",
  staticContentListing: "/pages/index",
  staticContentEdit: "/pages/edit",
  enquiriesListing: "get-enquiries-list",
  addEnquiries: "add-enquiry",
  updateEnquiries: "update-enquiry-details",
  listChannels: "channels-list",
  deleteChannels: "delete-fan-channel",
  getAllPermissions: "All",
  createRole: "/role/create",
  addCelebrity: "add-celebrity-data",
  updateCelebrity: "COMING_SOON",
  deleteCelebrity: "COMING_SOON",
  importCelebrity: "import-file-listing",
  rolesListing: "/role/index",
  deleteRoles: "/role/destroy",
  celebrityListing: "get-celebrity-data-listing",
  updateRole: "/role/edit",
  changeCelebrityListing: "change-celebrity-status",
  createSubAdmin: "/subadmin/create",
  listSubAdmins: "/subadmin/index",
  editSubAdmin: "/subadmin/edit",
  deleteSubAdmin: "/subadmin/destroy",
  getSubAdminById: "/subadmin/show/:id",
  verifyYouTuber: "verify-celebrity",
  rejectYouTuber: "reject-celebrity",
  listWithDrawRequest: "withdraw-requests/list",
  changeWithDrawRequestStatus: "withdraw-requests/change-status",
  getAdminSetting: "get-settings",
  updateAdminSetting: "update-settings",
  searchLog: "searchlogs/list",
  broadcastNotificationList: "broadcast-notifications/history",
  broadcastNotificationSend: "broadcast-notifications/send",
  // emailTemplatesList: "email-templates/list",
  // emailTemplateUpdate: "email-templates/update",
  emailTemplateById: "email-templates/findbyid",
  transactionHistory: "get-transaction-history",
  getFanBookingHistory: "get-fan-booking-history",
  getUserTransactionList: "get-user-transaction-list",
  getCelebrityReportedByFan: "get-celebrities-reportedto-fan",
  refundRequestChangeStatus: "refund-requests/change-status",
  refundRequestList: "refund-requests/list",
  widthRequestCelebrity: "withdraw-requests/list-by-userid",
  subadminStatus: "/subadmin/activation",
  roleStatus: "/role/activation",
  roleShow: "/role/show/:id",
  nftList: "/nft/index",
  nftView: "/nft/fetch/:id",
  nftDelete: "/nft/delete/:id",
  subscriberList: "/subscriber/index",
  emailTemplatesList: "/templates/list",
  emailTemplateUpdate: "/templates/update",
  nftReportList: "/reports/index",
  nftReport: "/nft/reports",
  nftBlock: "/nft/block",
};

export default permissions;
